import "./App.css";
import { createClient } from "@supabase/supabase-js";
const supabase = createClient(
  "https://qzvakezfzkwkezgncgnh.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF6dmFrZXpmemt3a2V6Z25jZ25oIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxMDkzNzc4MSwiZXhwIjoyMDI2NTEzNzgxfQ.KQJsqdpLFzUTFIVhzxZGI6QSB9aTMK4foAJTn6BSzKM"
);

const uploadFormSubmit = (e) => {
  e.preventDefault();

  const sumbitButton = document.querySelector("button[type=submit]");
  sumbitButton.disabled = true;
  sumbitButton.innerHTML = "Yükleniyor... Lütfen bekleyin.";

  const fileInput = document.querySelector("input[type=file]");
  const file = fileInput.files[0];

  supabase.storage
    .from("videos")
    .upload("video.mp4", file, {
      cacheControl: "3600",
      upsert: true,
    })
    .then((response) => {
      if (response.error) return alert("HATA: " + response.error.message);
      console.log(response);
      alert("Video yüklendi!");
    })
    .catch((error) => {
      console.log(error);
    });
};

function App() {
  return (
    <div className="App">
      <h1>
        Oynatılmasını istediğiniz videoyu yükleyin
        <a
          target="_blank"
          rel="noreferrer"
          href="https://qzvakezfzkwkezgncgnh.supabase.co/storage/v1/object/public/videos/video.mp4"
        >
          . Aktif video linki
        </a>
      </h1>
      <form id="uploadForm" onSubmit={(e) => uploadFormSubmit(e)}>
        <input type="file" />
        <br />
        <button type="submit">Kaydet!</button>
      </form>
      <hr />

      <h3>Aşağıdaki resmi okutarak videoyu oynatın.</h3>
      <img width={"100%"} style={{ maxWidth: "500px" }} src="one.png" alt="" />
    </div>
  );
}

export default App;
